import html from './preferences.html';
import lang from './preferences.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';

export default {
    template: html,

    i18n: {
        messages: lang,
    },

    mixins: [
        mixins.loadDataOnCreate,
        mixins.storeAccess,
    ],

    data: function () {
        return {
            language: this.$store.getters['asis/ui/lang'],
            tfa: {
                option: null,
                password: '',
                qrcode_data: null,
                secret: null,
                error: null,
                loading: false,
            }
        }
    },

    methods: {
        load() {
            server.request(
                'system-usr', 'preferences-get', {}, null, 
                (data) => {
                    this.tfa.option = data.tfa;
                }
            );
        },

        submit() {
            server.request(
                'system-usr', 'preferences-save', {language:this.language}, null, 
                (data) => {
                    // if ok, go to previous view
                    this.store_dispatch('asis/ui/lang', this.language);
                    this.$router.back();
                }
            );
            return false;
        },

        update_tfa() {
            server.request(
                'system-usr', 'preferences-set-tfa', {tfa:this.tfa.option}, null, 
            );
        },

        tfa_qrcode() {
            if (this.tfa.password.empty()) {
                this.tfa.error = 'empty';
                return;
            }

            this.tfa.loading = true;
            this.tfa.error = null;
            server.request(
                'system-usr', 'preferences-tfa-qrcode', {password:this.tfa.password}, null, 
                (data) => {
                    this.tfa.qrcode_data = data.tfa_qrcode;
                    this.tfa.secret = data.secret;
                },
                (error) => {
                    this.tfa.error = error;
                }
            ).then(()=>{
                this.tfa.loading = false;
            })
        }
    }


};


